import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ChangePassword from '../containers/ChangePassword';
import CreateAccount from '../containers/CreateAccount';
import CreateRole from '../containers/CreateRole';
import Haamel from '../containers/Haamel';
import Jaygahdar from '../containers/Jaygahdar';
import Landing from '../containers/Landing';
import Login from '../containers/Login';
import MasoolPetroshimi from '../containers/MasoolPetroshimi';
import Sarparast from '../containers/Sarparast';
import PrivateRoute from './PrivateRoute'

const Root = () => {
  return (
    <Switch>
      <PrivateRoute path="/jaygahdar/" component={Jaygahdar} />
      <PrivateRoute path="/masool_petroshimi/" component={MasoolPetroshimi} />
      <PrivateRoute path="/haamel/" component={Haamel} />
      <PrivateRoute path="/sarparast/" component={Sarparast} />
      <PrivateRoute path="/create_account/" component={CreateAccount} />
      <PrivateRoute path="/add_details/:username/:type/" component={CreateRole} />
      <PrivateRoute path="/change_password/" component={ChangePassword} />
      <Route path="/login/" component={Login} />
      <Route path="/" component={Landing} />
    </Switch>
  );
};

export default Root;
