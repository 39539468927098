import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  getOneFuelValueAction,
  setFuelValueAction,
} from '../../redux/slices/MasoolPetroshimi';
import {
  addNotificationAction,
} from '../../redux/slices/notifications';
import { toPersianNumber } from '../../utils/translateNumber';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '20rem',
    fontSize: '1rem',
    textDecoration: 'none',
    overflow: 'hidden',
    boxShadow: '0 0 2rem -1.5rem rgba(0, 0, 0, 0.5)',
    transition: 'transform 0.1s ease-in-out',
    '&:hover': {
      transform: 'translateY(-0.2rem) scale(1.02)',
      boxShadow: '0 0.5em 0.5rem -0.5rem rgba(0, 0, 0, 0.5)',
    },
  },
  media: {
    height: '200px',
    width: '300px',
  },
  icon: {
    textAlign: 'center',
  },
});

const Index = ({
  setFuelValue,
  getOneFuelValue,
  addNotification,

  fuel,
  user,
}) => {
  const classes = useStyles();
  const [changeValue, setChangeValue] = useState('');
  const [stock, setStock] = useState();

  useEffect(() => {
    if (user?.id) {
      getOneFuelValue({ user_id: user.id }).then((action) => {
        setStock(action?.payload?.response?.stock);
      });
    }
  }, [user])

  const doChangeFuelValue = () => {
    if (!changeValue) {
      addNotification({
        message: 'لطفاً میزان تغییر را وارد کنید.',
        type: 'error',
      });
      return;
    }
    setFuelValue({ user_id: user.id, stock_change: changeValue }).then((action) => {
      if (action.meta.requestStatus == 'fulfilled') {
        setStock(action?.payload?.response?.stock);
        setChangeValue('');
      }
    })
  }

  return (
    <>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={fuel.picture}
          title={fuel.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h1" align='center'>
            {fuel.name}
          </Typography>
          <Typography gutterBottom variant="h5" align='center'>
            {`موجودی: ${toPersianNumber(stock)} لیتر`}
          </Typography>
          <TextField
            value={changeValue}
            inputProps={{ className: 'ltr-input' }}
            fullWidth
            label='میزان تغییر'
            onChange={(e) => setChangeValue(e.target.value)} />
          <Box mt={1}>
            <Button onClick={doChangeFuelValue} fullWidth variant='contained' color='primary'>
              {'ثبت'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.account.user,
})

export default connect(
  mapStateToProps,
  {
    setFuelValue: setFuelValueAction,
    getOneFuelValue: getOneFuelValueAction,
    addNotification: addNotificationAction,
  }
)(Index);
