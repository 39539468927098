import { combineReducers } from 'redux';

import { accountReducer } from './account';
import { HaamelReducer } from './Haamel';
import { JaygahdarReducer } from './Jaygahdar';
import { MasoolPetroshimiReducer } from './MasoolPetroshimi';
import { notificationReducer } from './notifications';
import { redirectReducer } from './redirect';
import { roleReducer } from './role';
import { SarparastReducer } from './Sarparast';
import { translatorReducer } from './translator';

const allReducers = combineReducers({
  Haamel: HaamelReducer,
  Jaygahdar: JaygahdarReducer,
  MasoolPetroshimi: MasoolPetroshimiReducer,
  Sarparast: SarparastReducer,
  account: accountReducer,
  role: roleReducer,
  notifications: notificationReducer,
  redirect: redirectReducer,
  Intl: translatorReducer,
});

export default allReducers;
