import { createSlice } from '@reduxjs/toolkit';

import { Apis } from '../apis';
import { createAsyncThunkApi } from '../apis/cerateApiAsyncThunk';
import {
  getOneFuelValueUrl,
  setFuelValueUrl,
} from '../constants/urls';

export const getOneFuelValueAction = createAsyncThunkApi(
  'MasoolPetroshimi/getOneFuelValueAction',
  Apis.GET,
  getOneFuelValueUrl,
  {
    defaultNotification: {
      error: 'مشکلی در دریافت موجودی سوخت وجود داشت.',
    },
  }
);

export const setFuelValueAction = createAsyncThunkApi(
  'MasoolPetroshimi/setFuelValueAction',
  Apis.POST,
  setFuelValueUrl,
  {
    defaultNotification: {
      success: 'میزان سوخت با موفقیت تغییر یافت.',
      error: 'مشکلی در تعیین سوخت وجود داشت.',
    },
  }
);

const initialState = {
  allRequests: [],
  allRefineries: [],
  allMissions: [],
};

const isFetching = (state) => {
  state.isFetching = true;
};

const isNotFetching = (state) => {
  state.isFetching = false;
};

const accountSlice = createSlice({
  name: 'problem',
  initialState,
  reducers: {
  },
  extraReducers: {
    // [getAllRequestsAction.pending.toString()]: isFetching,
    // [getAllRequestsAction.fulfilled.toString()]: (state, { payload: { response } }) => {
    //   state.allRequests = response;
    //   state.isFetching = false;
    // },
    // [getAllRequestsAction.rejected.toString()]: isNotFetching,
  },
});

export const { reducer: MasoolPetroshimiReducer } = accountSlice;
