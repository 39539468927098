import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import {
  getUserInformationAction,
  loginAction,
} from '../../redux/slices/account';
import { addNotificationAction } from '../../redux/slices/notifications';
import { toEnglishNumber } from '../../utils/translateNumber';

const InputFields = ({
  getUserInformation,
  login,

  isFetching,
  addNotification,
  token,
  user,
}) => {
  const [data, setData] = useState({
    password: '',
    username: '',
  });

  if (token && user) {
    if (user.user_type == 'COURIER') {
      return <Redirect to="/haamel" />;
    }
    if (user.user_type == 'SUPERVISOR' || user.user_type == 'ADMIN') {
      return <Redirect to="/sarparast" />;
    }
    if (user.user_type == 'PETROL_SUPERVISOR') {
      return <Redirect to="/masool_petroshimi" />;
    }
    if (user.user_type == 'STATION_HOLDER') {
      return <Redirect to="/jaygahdar" />;
    }
  }

  const isJustDigits = (number) => {
    var regex = new RegExp(`\\d{${number.length}}`);
    if (regex.test(toEnglishNumber(number))) {
      return true;
    } else {
      return false;
    }
  };

  const putData = (event) => {
    setData({
      ...data,
      [event.target.name]: toEnglishNumber(event.target.value),
    });
  };

  const doLogin = () => {
    const { username, password } = data;
    if (!username || !password) {
      addNotification({
        message: 'لطفاً همه‌ی موارد را تکمیل کنید.',
        type: 'error',
      });
      return;
    }
    login(data).then((action) => {
      if (action.meta.requestStatus == 'fulfilled') {
        getUserInformation({ username: action?.meta?.arg?.username });
      }
    });
  };

  return (
    <>
      <Grid item>
        <TextField
          autoComplete="on"
          variant="outlined"
          fullWidth
          onChange={(e) => {
            if (isJustDigits(e.target.value)) {
              putData(e);
            }
          }}
          value={data.username}
          name="username"
          label="تلفن همراه"
          inputProps={{ className: 'ltr-input' }}
          type="tel"
        />
      </Grid>

      <Grid item>
        <TextField
          autoComplete="on"
          variant="outlined"
          fullWidth
          onChange={putData}
          label="گذرواژه"
          name="password"
          inputProps={{ className: 'ltr-input' }}
          type="password"
        />
      </Grid>

      <Grid container item direction="row" justifyContent="center">
        <Button
          onClick={doLogin}
          variant="contained"
          color="primary"
          disabled={isFetching}
          fullWidth>
          ورود
        </Button>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.account.token,
  isFetching: state.account.isFetching,
  user: state.account.user,
});

export default connect(
  mapStateToProps,
  {
    login: loginAction,
    getUserInformation: getUserInformationAction,
    addNotification: addNotificationAction,
  }
)(InputFields);
