import { createSlice } from '@reduxjs/toolkit';

import { Apis } from '../apis';
import { createAsyncThunkApi } from '../apis/cerateApiAsyncThunk';
import {
  getCourierStatusUrl,
  getNextDestinationUrl,
  reachToDestinationUrl,
} from '../constants/urls';

export const getCourierStatusAction = createAsyncThunkApi(
  'Haamel/getCourierStatusAction',
  Apis.GET,
  getCourierStatusUrl,
  {
    defaultNotification: {
      error: 'مشکلی در دریافت اطلاعات حامل وجود داشت.',
    },
  }
);

export const reachToDestinationAction = createAsyncThunkApi(
  'Haamel/reachToDestinationAction',
  Apis.GET,
  reachToDestinationUrl,
  {
    defaultNotification: {
      success: 'احسنت!',
      error: 'مشکلی در ثبت رسیدن به مقصد وجود داشت.',
    },
  }
);

const initialState = {
};

const isFetching = (state) => {
  state.isFetching = true;
};

const isNotFetching = (state) => {
  state.isFetching = false;
};

const accountSlice = createSlice({
  name: 'problem',
  initialState,
  reducers: {
  },
  extraReducers: {

    // [createMissionAction.pending.toString()]: isFetching,
    [getCourierStatusAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.courierStatus = response;
      state.mission = response.mission;
      state.isFetching = false;
    },
    // [createMissionAction.rejected.toString()]: isNotFetching,

    [reachToDestinationAction.pending.toString()]: isFetching,
    [reachToDestinationAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.mission = response;
      state.isFetching = false;
    },
    [reachToDestinationAction.rejected.toString()]: isNotFetching,

  },
});

export const { reducer: HaamelReducer } = accountSlice;
