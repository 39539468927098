import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';

import { addNotificationAction } from '../../redux/slices/notifications';
import {
  createHaamelAction,
} from '../../redux/slices/role';

const InputFields = ({
  isFetching,
  createHaamel,
  addNotification,
}) => {
  const { username } = useParams();

  const [data, setData] = useState({
    capacity: '',
  });

  const putData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const doCreateJaygahdarRole = () => {
    const { capacity } = data;
    if (!capacity) {
      addNotification({
        message: 'لطفاً همه‌ی موارد را پر کنید.',
        type: 'error',
      });
      return;
    }
    createHaamel({
      username,
      capacity,
    }).then((action) => {
      if (action.meta.requestStatus == 'fulfilled') {
        window.location.href = '/sarparast'
      }
    });
  };

  return (
    <>
      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          onChange={putData}
          value={data.latitude}
          name="capacity"
          label="گنجایش حمل"
          inputProps={{ className: 'ltr-input' }}
        />
      </Grid>

      <Grid container item direction="row" justifyContent="center">
        <Button
          onClick={doCreateJaygahdarRole}
          variant="contained"
          color="primary"
          disabled={isFetching}
          fullWidth>
          ثبت
        </Button>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.account.isFetching,
});

export default connect(mapStateToProps, {
  createHaamel: createHaamelAction,
  addNotification: addNotificationAction,
})(InputFields);
