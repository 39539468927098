import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { addNotificationAction } from '../../redux/slices/notifications';
import {
  createMissionAction,
  getAllHaamelsAction,
  getAllRefineriesAction,
  getAllRequestsAction,
} from '../../redux/slices/Sarparast';
import { toPersianNumber } from '../../utils/translateNumber';
import Layout from '../Layout';

const useStyles = makeStyles((theme) => ({
}));

const Index = ({
  addNotification,
  createMission,
  getAllRequests,
  getAllRefineries,
  getAllHaamels,

  allRequests,
  allRefineries,
  allHaamels,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedRequests, setSelectedRequests] = React.useState([]);
  const [selectedHaamel, setSelectedHaamel] = React.useState();
  const [selectedRefinery, setSelectedRefinery] = React.useState();

  useEffect(() => {
    getAllRequests({});
    getAllHaamels({});
    getAllRefineries({});
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRequests(value);
  };

  const doCreateMission = () => {
    if (selectedRequests.length == 0 || !selectedHaamel || !selectedRefinery) {
      addNotification({
        message: 'لطفاً همه‌ی موارد را تکمیل کنید.',
        type: 'error',
      });
      return;
    }
    createMission({
      request_ids: selectedRequests,
      petrol_refinery_id: selectedRefinery,
      courier_id: selectedHaamel,
    })
  }

  console.log(allRequests);

  return (
    <Grid container item justify='center' spacing={2}>
      <Grid item xs={12} md={3}>
        <FormControl
          fullWidth
          variant="outlined">
          <InputLabel>درخواست‌ها</InputLabel>
          <Select
            multiple
            onChange={handleChange}
            value={selectedRequests}
            label="درخواست‌ها">
            {allRequests.map((request) => {
              return (
                <MenuItem key={request.id} value={request.id}>
                  {`جایگاه شماره ${toPersianNumber(request?.station?.id)} - سوخت درخواستی: ${toPersianNumber(request.amount)} لیتر`}
                </MenuItem>
              )
            })}
            {allRequests.length == 0 &&
              <MenuItem value={''}>
                {'موردی وجود ندارد.'}
              </MenuItem>
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <FormControl
          fullWidth
          variant="outlined"
          className={classes.formControl}>
          <InputLabel>پتروشیمی</InputLabel>
          <Select
            onChange={(e) => setSelectedRefinery(e.target.value)}
            label="پتروشیمی">
            {allRefineries.map((refinery) => {
              return (
                <MenuItem key={refinery.id} value={refinery.id}>
                  {`پتروشیمی شماره ${toPersianNumber(refinery.id)} - موجودی: ${toPersianNumber(refinery.stock)} لیتر`}
                </MenuItem>
              )
            })}
            {allRefineries.length == 0 &&
              <MenuItem value={''}>
                {'موردی وجود ندارد.'}
              </MenuItem>
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <FormControl
          fullWidth
          variant="outlined"
          className={classes.formControl}>
          <InputLabel>حامل</InputLabel>
          <Select
            onChange={(e) => setSelectedHaamel(e.target.value)}
            label="حامل">
            {allHaamels.map((haamel) => {
              return (
                <MenuItem key={haamel.id} value={haamel.id}>
                  {`حامل شماره ${toPersianNumber(haamel.id)} - ${haamel?.user?.first_name} ${haamel?.user?.last_name}`}
                </MenuItem>
              )
            })}
            {allHaamels.length == 0 &&
              <MenuItem value={''}>
                {'موردی وجود ندارد.'}
              </MenuItem>
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item container xs={12} md={3}>
        <Button onClick={doCreateMission} fullWidth variant='contained' color='primary'>
          {'ایجاد ماموریت'}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  allRequests: state.Sarparast.allRequests,
  allRefineries: state.Sarparast.allRefineries,
  allHaamels: state.Sarparast.allHaamels,
});

export default connect(
  mapStateToProps,
  {
    addNotification: addNotificationAction,
    createMission: createMissionAction,
    getAllRequests: getAllRequestsAction,
    getAllRefineries: getAllRefineriesAction,
    getAllHaamels: getAllHaamelsAction,
  }
)(Index);