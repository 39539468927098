import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import {
  changePasswordAction,
} from '../../redux/slices/account';
import { addNotificationAction } from '../../redux/slices/notifications';
import { toEnglishNumber } from '../../utils/translateNumber';

const InputFields = ({
  isFetching,
  changePassword,
  addNotification,
  token,
}) => {
  const [data, setData] = useState({
    password: '',
    confirmationPassword: '',
  });


  const putData = (event) => {
    setData({
      ...data,
      [event.target.name]: toEnglishNumber(event.target.value),
    });
  };

  const doChangePassword = () => {
    const { password, confirmationPassword } = data;
    if (!password || !confirmationPassword) {
      addNotification({
        message: 'لطفاً همه‌ی موارد را تکمیل کنید.',
        type: 'error',
      });
      return;
    }

    if (password !== confirmationPassword) {
      addNotification({
        message: 'رمزهای وارد‌شده مشابه هم نیستند.',
        type: 'error',
      });
      return;
    }
    changePassword(data);
  };

  return (
    <>

      <Grid item>
        <TextField
          autoComplete="on"
          variant="outlined"
          fullWidth
          onChange={putData}
          label="گذرواژه جدید"
          name="password"
          inputProps={{ className: 'ltr-input' }}
          type="password"
        />
      </Grid>

      <Grid item>
        <TextField
          autoComplete="on"
          variant="outlined"
          fullWidth
          onChange={putData}
          label="تکرار گذرواژه"
          type="password"
          inputProps={{ className: 'ltr-input' }}
          name="confirmationPassword"
        />
      </Grid>

      <Grid container item direction="row" justifyContent="center">
        <Button
          onClick={doChangePassword}
          variant="contained"
          color="primary"
          disabled={isFetching}
          fullWidth>
          تغییر
        </Button>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.account.isFetching,
});

export default connect(mapStateToProps, {
  changePassword: changePasswordAction,
  addNotification: addNotificationAction,
})(InputFields);
