import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import Notification from '../../components/Cards/Notification';
import {
  createRequestAction,
} from '../../redux/slices/Jaygahdar';
import {
  addNotificationAction,
} from '../../redux/slices/notifications';
import Layout from '../Layout';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const Index = ({
  createRequest,
  addNotification,

  user,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [requestedAmount, setRequestedAmount] = useState('');
  const [fuelType, setFuelType] = useState();

  const doCreateRequestFuel = () => {
    if (!requestedAmount || !fuelType) {
      addNotification({
        message: 'لطفاً همه‌ی موارد را تکمیل کنید.',
        type: 'error',
      });
      return;
    }
    createRequest({ username: user.username, amount: requestedAmount, fuel_type: fuelType })
    setRequestedAmount('')
  }

  return (
    <Layout appbarMode='JAYGAHDAR'>
      <Grid container justify='center' spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom variant='body2'>
                  {'به پنل جایگاه‌داران خوش‌آمدید! در این صفحه می‌توانید بسته به نیاز جایگاهتان، سوخت مورد نظر خود را درخواست کنید و منتظر باشید تا یکی از حاملان، در اسرع وقت، آن را به دست شما برساند.'}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ className: 'ltr-input' }}
                  value={requestedAmount}
                  onChange={(e) => setRequestedAmount(e.target.value)}
                  label='میزان سوخت'
                  variant='outlined' fullWidth />
              </Grid>
              <Grid item container xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined">
                  <InputLabel>نوع سوخت</InputLabel>
                  <Select
                    name="province"
                    onChange={(e) => setFuelType(e.target.value)}
                    label="نوع سوخت">
                    <MenuItem value={'PETROL'}>
                      {'بنزین معمولی'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={doCreateRequestFuel} variant='contained' fullWidth color='primary'>
                  {'درخواست سوخت'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item container xs={12} md={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom variant='h1' align='center'>
                  {'اطلاعیه‌ها'}
                </Typography>
                <Divider />
              </Grid>
              {/* <Grid item xs={12}>
                <Notification notification={{
                  title: 'انتقال موفق سوخت',
                  body: 'محموله‌ای شامل ۲۰۰ لیتر بنزین سوپر از پتروشیمی فلان به جایگاه شما انتقال یافت.',
                }} />
              </Grid>
              <Grid item xs={12}>
                <Notification notification={{
                  title: 'انتقال ناموفق سوخت',
                  body: 'محموله‌ای شامل ۳۰۰ لیتر گازوئیل، توسط آدم‌خوارها خورده شد. شوخوش!',
                }} />
              </Grid>
              <Grid item xs={12}>
                <Notification notification={{
                  title: 'انتقال موفق سوخت',
                  body: 'محموله‌ای شامل ۲۰۰ لیتر بنزین سوپر از پتروشیمی فلان به جایگاه شما انتقال یافت.',
                }} />
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout >
  );
};

const mapStateToProps = (state) => ({
  user: state.account.user,
});

export default connect(
  mapStateToProps,
  {
    createRequest: createRequestAction,
    addNotification: addNotificationAction,
  }
)(Index);