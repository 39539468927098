import { createSlice } from '@reduxjs/toolkit';

import { Apis } from '../apis';
import { createAsyncThunkApi } from '../apis/cerateApiAsyncThunk';
import {
  changePasswordUrl,
  createAccountUrl,
  loginUrl,
  userInformationUrl,
} from '../constants/urls';

export const loginAction = createAsyncThunkApi(
  'users/loginAction',
  Apis.POST,
  loginUrl,
  {
    defaultNotification: {
      success: 'خوش آمدید!',
      error: 'ایرادی در ورود وجود داشت.',
    },
  }
);

export const getUserInformationAction = createAsyncThunkApi(
  'users/getUserInformationAction',
  Apis.GET,
  userInformationUrl,
  {
    defaultNotification: {
      error: 'مشکلی در دریافت اطلاعات کاربر وجود داشت.',
    },
  }
);

export const changePasswordAction = createAsyncThunkApi(
  'users/changePasswordAction',
  Apis.POST,
  changePasswordUrl,
  {
    defaultNotification: {
      success: 'رمز عبور با موفقیت تغییر یافت.',
      error: 'مشکلی در تغییر رمز عبور وجود داشت.',
    },
  }
);


const initialState = {
  token: null,
  user: {},
  notifications: [],
};


const isFetching = (state) => {
  state.isFetching = true;
};

const isNotFetching = (state) => {
  state.isFetching = false;
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: {
    [loginAction.pending.toString()]: isFetching,
    [loginAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.token = response.access;
      state.isFetching = false;
    },
    [loginAction.rejected.toString()]: isNotFetching,


    [getUserInformationAction.pending.toString()]: isFetching,
    [getUserInformationAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.user = response;
      state.isFetching = false;
    },
    [getUserInformationAction.rejected.toString()]: isNotFetching,
  },
});

export const { logout: logoutAction } = accountSlice.actions;

export const { reducer: accountReducer } = accountSlice;
