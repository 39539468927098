import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';

import { addNotificationAction } from '../../redux/slices/notifications';
import {
  createMasoolPetroshimiAction,
} from '../../redux/slices/role';

const InputFields = ({
  isFetching,
  createMasoolPetroshimi,
  addNotification,
}) => {
  const { username } = useParams();
  const [stock, setStock] = useState();
  const [data, setData] = useState({
    latitude: '',
    longitude: '',
    address_text: '',
  });

  const putData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const doCreateJaygahdarRole = () => {
    const { latitude, longitude, address_text } = data;
    if (!stock || !latitude || !longitude || !address_text) {
      addNotification({
        message: 'لطفاً همه‌ی موارد را پر کنید.',
        type: 'error',
      });
      return;
    }
    createMasoolPetroshimi({
      username,
      address: data,
      stock,
    }).then((action) => {
      if (action.meta.requestStatus == 'fulfilled') {
        window.location.href = '/sarparast'
      }
    });
  };

  return (
    <>
      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          onChange={putData}
          value={data.latitude}
          name="latitude"
          label="طول جغرافیایی "
          inputProps={{ className: 'ltr-input' }}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          onChange={putData}
          value={data.longitude}
          name="longitude"
          label="عرض جغرافیایی"
          inputProps={{ className: 'ltr-input' }}
        />
      </Grid>

      <Grid item>
        <TextField
          multiline
          variant="outlined"
          fullWidth
          onChange={putData}
          label="آدرس"
          name="address_text"
        />
      </Grid>

      <Grid item>
        <TextField
          multiline
          variant="outlined"
          fullWidth
          onChange={(e) => setStock(e.target.value)}
          label="گنجایش مخزن"
          name="capacity"
        />
      </Grid>

      <Grid container item direction="row" justifyContent="center">
        <Button
          onClick={doCreateJaygahdarRole}
          variant="contained"
          color="primary"
          disabled={isFetching}
          fullWidth>
          ثبت
        </Button>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.account.isFetching,
});

export default connect(mapStateToProps, {
  createMasoolPetroshimi: createMasoolPetroshimiAction,
  addNotification: addNotificationAction,
})(InputFields);
