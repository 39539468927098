import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import Notification from '../../components/Cards/Notification';
import {
  reachToDestinationAction,
} from '../../redux/slices/Haamel';
import { toPersianNumber } from '../../utils/translateNumber'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const Index = ({
  reachToDestination,
  mission,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const doReachToDestination = () => {
    reachToDestination({});
  }

  console.log(mission)
  const requests = mission.requests;

  return (
    <Grid container justify='center' spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant='body2'>
                {'ماموریت جدیدی به شما محول شده. برای انجام این ماموریت، صرفاً به ترتیب به سراغ هر مقصد بروید و پس از رسیدن به مقصد، روی دکمه‌ی «رسیدم!» کلیک کنید.'}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={doReachToDestination} variant='contained' fullWidth color='primary'>
                {'رسیدم!'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item container xs={12} md={8}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant='h1' align='center'>
                {'مقاصد'}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Notification disabled={mission.step > 0} notification={{
                title: `مقصد شماره‌ی ۱`,
                body: `آدرس: ${mission?.petrol_refinery?.address?.address_text} - طول جغرافیایی: ${toPersianNumber(mission?.petrol_refinery?.address?.latitude || 0)} - عرض جغرافیایی: ${toPersianNumber(mission?.petrol_refinery?.address?.longitude || 0)}`,
              }} />
            </Grid>
            {requests?.map((request, index) => {
              return (
                <Grid item key={request.id} xs={12}>
                  <Notification disabled={mission.step > index + 1} notification={{
                    title: `مقصد شماره‌ی ${toPersianNumber(index + 2)}`,
                    body: `آدرس: ${request?.station?.address?.address_text} - طول جغرافیایی: ${toPersianNumber(request?.station?.address?.latitude || 0)} - عرض جغرافیایی: ${toPersianNumber(request?.station?.address?.longitude || 0)}`,
                  }} />
                </Grid>
              )
            })}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  mission: state.Haamel.mission,
});

export default connect(
  mapStateToProps,
  {
    reachToDestination: reachToDestinationAction,
  }
)(Index);