import {
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import {
  getPlayerAction,
  getUserNotificationsAction,
} from '../../redux/slices/account';
import WideLayout from '../WideLayout';
import CreateNewMission from './CreateNewMission';
import Missions from './Missions';

const useStyles = makeStyles((theme) => ({
}));

const Index = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <WideLayout appbarMode='SARPARAST'>
      <Grid container justify='center' alignItems='flex-start' spacing={2}>
        <Grid item xs={12}>
          <CreateNewMission />
        </Grid>
        <Grid item xs={12}>
          <Missions />
        </Grid>
      </Grid>
    </WideLayout>
  );
};

const mapStateToProps = (state) => ({
});

export default connect(
  mapStateToProps,
  {
  }
)(Index);