// Account:
export const loginUrl = 'account/login/';
export const userInformationUrl = ({ username }) => `account/${username}/`;
export const createAccountUrl = 'account/';
export const changePasswordUrl = 'account/change-password/';

// Sarparast:
export const getAllRequestsUrl = 'fuel/request/';
export const getAllRefineriesUrl = 'fuel/petrol_refinery/';
export const createMissionUrl = 'fuel/mission/';
export const getAllFreeHaamelsUrl = 'fuel/courier/?status=READY';

// Jaygahdar
export const createRequestUrl = 'fuel/request/';

// Haamel
export const getCourierStatusUrl = 'fuel/courier/status/';
export const getNextDestinationUrl = ({ missionId }) => `fuel/mission/${missionId}/status/`;
export const reachToDestinationUrl = `fuel/courier/reach_to_destination/`;

// MasoolPetroshimi:
export const getOneFuelValueUrl = ({ user_id }) => `/fuel/petrol_refinery/?user_id=${user_id}`;
export const setFuelValueUrl = '/fuel/petrol_refinery/update_fuel/';

// Role
export const createJaygahdarRoleUrl = '/fuel/gas_station/'
export const createMasoolPetroshimiRoleUrl = '/fuel/petrol_refinery/'
export const createHaamelRoleUrl = '/fuel/courier/'
export const getAllHaamelsUrl = '/account/?type=COURIER'

// Game:
export const getPlayerUrl = 'game/player/';
export const userNotificationsUrl = 'game/notification/'

export const allExchangesUrl = 'game/exchange/all/'
export const playerExchangesUrl = 'game/exchange/'
export const createNewExchangesUrl = 'game/exchange/create/'


// Problem:
export const getProblemFromGroupUrl = ({ problemGroupId }) => `game / getproblemfromgroup / ${problemGroupId} /`;
export const hasPlayerGotProblemUrl = ({ problemGroupId }) => `game/isproblemgotenfromgroup/${problemGroupId}/`; // todo: fix غلط املایی
export const submitProblemUrl = ({ submitId, problemId }) => `game/submitanswer/${submitId}/${problemId}/`;


// Game:
export const getAllCheckableObjectsUrl = 'game/getallobjects/';
export const getPlayerCheckableObjectsUrl = 'game/getmyobjects/';
export const checkObjectsUrl = ({ checkableObjectId }) => `game/checkobject/${checkableObjectId}/`;
export const getFamousPersonUrl = 'game/getfamousepersons/';

