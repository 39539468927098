import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { addNotificationAction } from '../../redux/slices/notifications';
import {
  createAccountAction,
} from '../../redux/slices/role';
import { toEnglishNumber } from '../../utils/translateNumber';

const InputFields = ({
  isFetching,
  createAccount,
  addNotification,

  newUser,
}) => {
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    password: '',
    confirmationPassword: '',
    user_type: '',
  });

  if (newUser?.username) {
    return <Redirect to={`add_details/${newUser.username}/${data.user_type}`} />;
  }

  const putData = (event) => {
    setData({
      ...data,
      [event.target.name]: toEnglishNumber(event.target.value),
    });
  };

  const isJustDigits = (number) => {
    var regex = new RegExp(`\\d{${number.length}}`);
    if (regex.test(toEnglishNumber(number))) {
      return true;
    } else {
      return false;
    }
  };

  const isPhoneNumberValid = (phoneNumber) => {
    var regex = new RegExp('^(\\+98|0)?9\\d{9}$');
    if (regex.test(phoneNumber)) {
      return phoneNumber;
    } else {
      return;
    }
  };

  const doRegistration = () => {
    const { first_name, last_name, user_type, username, password, confirmationPassword } = data;
    if (!first_name || !last_name || !user_type || !username || !password || !confirmationPassword) {
      addNotification({
        message: 'لطفاً همه‌ی موارد را پر کنید.',
        type: 'error',
      });
      return;
    }

    if (password !== confirmationPassword) {
      addNotification({
        message: 'رمزهای وارد‌شده مشابه هم نیستند.',
        type: 'error',
      });
      return;
    }

    // if (!isPhoneNumberValid(data.username)) {
    //   addNotification({
    //     message: 'شماره‌تلفن واردشده معتبر نیست.',
    //     type: 'error',
    //   });
    //   return;
    // }
    createAccount(data);
  };

  return (
    <>
      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          onChange={putData}
          value={data.first_name}
          name="first_name"
          label="نام"
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          onChange={putData}
          value={data.last_name}
          name="last_name"
          label="نام خانوادگی"
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          onChange={(e) => {
            if (isJustDigits(e.target.value)) {
              putData(e);
            }
          }}
          value={data.username}
          name="username"
          label="شماره تلفن همراه"
          inputProps={{ className: 'ltr-input' }}
          type="tel"
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          onChange={putData}
          label="گذرواژه"
          name="password"
          inputProps={{ className: 'ltr-input' }}
          type="password"
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          onChange={putData}
          label="تکرار گذرواژه"
          type="password"
          inputProps={{ className: 'ltr-input' }}
          name="confirmationPassword"
        />
      </Grid>

      <Grid item>
        <FormControl fullWidth variant="outlined">
          <InputLabel>نقش</InputLabel>
          <Select label="نقش" name='user_type' onChange={putData}>
            <MenuItem value={'PETROL_SUPERVISOR'}>
              {'مسئول پتروشیمی'}
            </MenuItem>
            <MenuItem value={'COURIER'}>
              {'حامل'}
            </MenuItem>
            <MenuItem value={'STATION_HOLDER'}>
              {'جایگاه‌دار'}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid container item direction="row" justifyContent="center">
        <Button
          onClick={doRegistration}
          variant="contained"
          color="primary"
          disabled={isFetching}
          fullWidth>
          ثبت
        </Button>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.account.isFetching,
  newUser: state.role.newUser,
});

export default connect(mapStateToProps, {
  createAccount: createAccountAction,
  addNotification: addNotificationAction,
})(InputFields);
