import { createSlice } from '@reduxjs/toolkit';

import { Apis } from '../apis';
import { createAsyncThunkApi } from '../apis/cerateApiAsyncThunk';
import {
  createMissionUrl,
  getAllFreeHaamelsUrl,
  getAllRefineriesUrl,
  getAllRequestsUrl,
} from '../constants/urls';

export const getAllRequestsAction = createAsyncThunkApi(
  'Sarparast/getAllRequestsAction',
  Apis.GET,
  getAllRequestsUrl,
);

export const getAllRefineriesAction = createAsyncThunkApi(
  'Sarparast/getAllRefineriesAction',
  Apis.GET,
  getAllRefineriesUrl,
);

export const getAllHaamelsAction = createAsyncThunkApi(
  'Sarparast/getAllHaamelsAction',
  Apis.GET,
  getAllFreeHaamelsUrl,
  {
    defaultNotification: {
      error: 'مشکلی در دریافت حامل‌های آزاد وجود داشت.',
    },
  }
);

export const getAllMissionsAction = createAsyncThunkApi(
  'Sarparast/getAllMissionsAction',
  Apis.GET,
  createMissionUrl,
  {
    defaultNotification: {
      error: 'مشکلی در دریافت ماموریت‌ها وجود داشت.',
    },
  }
);

export const createMissionAction = createAsyncThunkApi(
  'Sarparast/createMissionAction',
  Apis.POST,
  createMissionUrl,
  {
    defaultNotification: {
      success: 'ماموریت با موفقیت ایجاد شد.',
      error: 'مشکلی در ایجاد ماموریت جدید وجود داشت.',
    },
  }
);

const initialState = {
  allRequests: [],
  allRefineries: [],
  allMissions: [],
  allHaamels: [],
};

const isFetching = (state) => {
  state.isFetching = true;
};

const isNotFetching = (state) => {
  state.isFetching = false;
};

const slice = createSlice({
  name: 'Sarparast',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getAllRequestsAction.pending.toString()]: isFetching,
    [getAllRequestsAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.allRequests = response;
      state.isFetching = false;
    },
    [getAllRequestsAction.rejected.toString()]: isNotFetching,


    [getAllRefineriesAction.pending.toString()]: isFetching,
    [getAllRefineriesAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.allRefineries = response;
      state.isFetching = false;
    },
    [getAllRefineriesAction.rejected.toString()]: isNotFetching,


    [createMissionAction.pending.toString()]: isFetching,
    [createMissionAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      window.location.reload();
      state.isFetching = false;
    },
    [createMissionAction.rejected.toString()]: isNotFetching,


    [getAllMissionsAction.pending.toString()]: isFetching,
    [getAllMissionsAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.allMissions = response;
      state.isFetching = false;
    },
    [getAllMissionsAction.rejected.toString()]: isNotFetching,


    [getAllHaamelsAction.pending.toString()]: isFetching,
    [getAllHaamelsAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.allHaamels = response;
      state.isFetching = false;
    },
    [getAllHaamelsAction.rejected.toString()]: isNotFetching,

  },
});

export const { reducer: SarparastReducer } = slice;
