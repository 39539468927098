import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import Notification from '../../components/Cards/Notification';
import {
  getAllMissionsAction,
} from '../../redux/slices/Sarparast';
import { toPersianNumber } from '../../utils/translateNumber';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const Index = ({
  getAllMissions,

  allMissions,
}) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getAllMissions({});
  }, [])

  const doneMissions = allMissions.filter((mission) => mission.is_done == true)
  const doingMissions = allMissions.filter((mission) => mission.is_done == false)

  return (
    <Grid container justify='center' spacing={2}>
      <Grid item xs={12} md={3}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant='body2'>
                {'به پنل سرپرست خوش‌آمدید! در این جا می‌توانید لیستی از درخواست‌ها، پتروشیمی‌ها و میزان سوخت موجود در آن‌ها و هم‌چنین، حامل‌های فعال را مشاهده کنید. به‌علاوه می‌توانید ماموریت جدید بسازید.'}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button disabled variant='contained' fullWidth color='primary'>
                {'جستجو'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item container xs={12} md={9} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom variant='h2' align='center'>
                  {'ماموریت‌های در حال انجام'}
                </Typography>
                <Divider />
              </Grid>
              {doingMissions.map((mission) => {
                return (
                  <Grid item key={mission.id} xs={12}>
                    <Notification notification={{
                      title: `ماموریت شماره ${toPersianNumber(mission.id)}`,
                      body: `حامل شماره‌ی ${toPersianNumber(mission.courier?.id || 0)} در حال حرکت به سمت ${toPersianNumber(mission?.requests?.length || 0)} جایگاه است.`,
                    }} />
                  </Grid>
                )
              })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom variant='h2' align='center'>
                  {'ماموریت‌های انجام‌شده'}
                </Typography>
                <Divider />
              </Grid>
              {doneMissions.map((mission) => {
                return (
                  <Grid item key={mission.id} xs={12}>
                    <Notification disabled notification={{
                      title: `ماموریت شماره ${toPersianNumber(mission.id)}`,
                      body: `حامل شماره‌ی ${toPersianNumber(mission.courier?.id || 0)}، سوخت مورد نیاز ${toPersianNumber(mission?.requests?.length || 0)} جایگاه را تامین کرد.`,
                    }} />
                  </Grid>
                )
              })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>);
};

const mapStateToProps = (state) => ({
  allMissions: state.Sarparast.allMissions,
});

export default connect(
  mapStateToProps,
  {
    getAllMissions: getAllMissionsAction,
  }
)(Index);