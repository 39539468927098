export const errorHandler = (
  error,
  dispatch,
  rejectWithValue,
  errorMessage,
  showHttpError
) => {

  if (error.response.data.code == 'token_not_valid') {
    dispatch({ type: 'account/logout' });
    return rejectWithValue({
      message: 'نشست شما به پایان رسیده است. لطفا مجدداً وارد شوید.',
    });
  }

  if (error.response.status == 401) {
    return rejectWithValue({
      message: 'نام کاربری یا رمز عبور اشتباه است.',
    });
  }

  if (error?.response?.data?.message) {
    return rejectWithValue({
      message: error?.response?.data?.message,
    });
  }

  if (error?.response?.data?.detail) {
    return rejectWithValue({
      message: error?.response?.data?.detail,
    });
  }

  switch (error.response.status) {
    case 404:
      return rejectWithValue({
        message: 'موردی یافت نشد!',
      });
    case 500:
      return rejectWithValue({
        message: 'ایراد سروری پیش آمده است. لطفاً ما را در جریان بگذارید.',
      });
  }

  if (errorMessage) {
    return rejectWithValue({ message: errorMessage });
  }

  if (showHttpError && error.response.data?.error) {
    return rejectWithValue({ message: error.response.data.error });
  }

  return rejectWithValue();
};
