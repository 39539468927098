import Haamel from './Haamel';
import Jaygahdar from './Jaygahdar';
import MasoolPetroshimi from './MasoolPetroshimi';
import Sarparast from './Sarparast';

const AppbarModes = {
  JAYGAHDAR: Jaygahdar,
  MASOOL_PETROSHIMI: MasoolPetroshimi,
  HAAMEL: Haamel,
  SARPARAST: Sarparast,
};

export default AppbarModes;
