import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AppBar from '../components/Appbar/ResponsiveAppBar';

const useStyles = makeStyles((theme) => ({
  background: ({ backgroundImage }) => ({
    transform: 'scale(1.1)',
    height: '100vh',
    width: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    background: `url(${process.env.PUBLIC_URL + backgroundImage}) no-repeat center center fixed`,
    opacity: '0.5',
    backgroundSize: 'cover',
    zIndex: '-1',
    animation: 'show-back 0.8s 0.3s both',
    filter: 'blur(3px)',
  }),
  container: {
    padding: theme.spacing(4),
    minHeight: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 'auto !important',
    marginLeft: 'auto !important',
  },
}));

const Layout = ({
  backgroundImage,
  appbarMode = 'SARPARAST',
  ...props
}) => {
  const classes = useStyles({ backgroundImage });

  return (
    <>
      <div className={classes.background} />
      <AppBar mode={appbarMode} position='relative' />
      <div className={classes.container}>{props.children}</div>
    </>
  );
};

const mapStateToProps = (state) => ({
});

export default connect(
  mapStateToProps,
  {
  }
)(Layout);