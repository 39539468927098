import React from 'react';
import { useTranslate } from 'react-redux-multilingual/lib/context';

import LogoButton from './components/LogoButton';
import LogoutButton from './components/LogoutButton';

const ArticleAppBarItems = () => {
  const t = useTranslate();
  const logoButton = <LogoButton href='/haamel' type='جایگاه‌دار' />;
  const logoutButton = <LogoutButton />

  return {
    desktopLeftItems: [logoutButton],
    desktopRightItems: [logoButton],
    mobileLeftItems: [logoutButton],
    mobileRightItems: [logoButton],
    mobileMenuListItems: [],
  };
};

export default ArticleAppBarItems;
