import {
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import FuelCard from '../../components/Cards/FuelCard';
import WideLayout from '../WideLayout';

const useStyles = makeStyles((theme) => ({
}));

const Index = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <WideLayout appbarMode='MASOOL_PETROSHIMI'>
      <Grid container justify='center' spacing={2}>
        <Grid item>
          <FuelCard fuel={{
            name: 'بنزین معمولی',
            picture: process.env.PUBLIC_URL + '/cars/car1.png',
          }} />
        </Grid>
      </Grid>
    </WideLayout>
  );
};

const mapStateToProps = (state) => ({
});

export default connect(
  mapStateToProps,
  {
  }
)(Index);