import {
  Button,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import {
  getCourierStatusAction,
} from '../../redux/slices/Haamel';
import Layout from '../Layout';
import AskReady from './AskReady';
import Mission from './Mission';

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: 10,
  },
}));

const Index = ({
  getCourierStatus,
  courierStatus,
  mission,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [ready, setReady] = useState(true);

  useEffect(() => {
    getCourierStatus({});
    const timer = setTimeout(() => {
      getCourierStatus({});
    }, 30000)
    return (() => {
      clearTimeout(timer);
    })
  }, [])


  return (
    <Layout appbarMode='HAAMEL'>
      {
        (courierStatus?.work_status == 'READY' || mission?.is_done) &&
        <AskReady setReady={() => { }} ready={ready} />
      }
      {
        (courierStatus?.work_status == 'BUSY' && !mission?.is_done) &&
        <Mission />
      }
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  courierStatus: state.Haamel.courierStatus,
  mission: state.Haamel.mission,
});

export default connect(
  mapStateToProps,
  {
    getCourierStatus: getCourierStatusAction,
  }
)(Index);