import {
  Button,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import FuelCard from '../../components/Cards/FuelCard';
import {
  getPlayerAction,
  getUserNotificationsAction,
} from '../../redux/slices/account';
import Layout from '../Layout';

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: 10,
  },
}));

const Index = ({
  ready, setReady,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container justify='center' spacing={2} direction='column' alignItems='center'>
      <Grid item>
        <img className={classes.img} alt='' width='200' src={process.env.PUBLIC_URL + '/ready-person.png'} />
      </Grid>
      <Grid item>
        <Typography align='center' variant='h2'>
          {'برای پذیرفتن ماموریت جدید آماده‌ای؟'}
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          onChange={() => {
            setReady(!ready);
          }}
          checked={ready} />
        <Typography align='center' variant='h5'>
          {ready ? 'آماده‌ام!' : 'نه فعلاً'}
        </Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
});

export default connect(
  mapStateToProps,
  {
  }
)(Index);