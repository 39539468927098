import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react'

import Layout from '../Layout';
import InputFields from './Fields'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  image: {
    height: '80vh',
    background: `url(${process.env.PUBLIC_URL}'/ZeroJourneyer/Dr.Rastaranj.png')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
  },
}))



const Index = () => {
  const classes = useStyles();

  return (
    <Layout appbarMode='SARPARAST'>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        xs={12} sm={8} md={6}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item container>
              <Grid
                container
                item
                direction='column'
                justifyContent='center'
                spacing={2}>
                <Grid item>
                  <Typography gutterBottom variant='h2' align='center'>{'ایجاد کاربر جدید'}</Typography>
                </Grid>
                <InputFields />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default (Index);