import { createSlice } from '@reduxjs/toolkit';

import { Apis } from '../apis';
import { createAsyncThunkApi } from '../apis/cerateApiAsyncThunk';
import {
  createRequestUrl,
} from '../constants/urls';

export const createRequestAction = createAsyncThunkApi(
  'Jaygahdar/createRequestAction',
  Apis.POST,
  createRequestUrl,
  {
    defaultNotification: {
      success: 'درخواست با موفقیت ثبت شد.',
      error: 'مشکلی در ثبت درخواست وجود داشت.',
    },
  }
);

const initialState = {
};

const isFetching = (state) => {
  state.isFetching = true;
};

const isNotFetching = (state) => {
  state.isFetching = false;
};

const accountSlice = createSlice({
  name: 'problem',
  initialState,
  reducers: {
  },
  extraReducers: {

    // [createMissionAction.pending.toString()]: isFetching,
    // [createMissionAction.fulfilled.toString()]: (state, { payload: { response } }) => {
    //   window.location.reload();
    //   state.isFetching = false;
    // },
    // [createMissionAction.rejected.toString()]: isNotFetching,

  },
});

export const { reducer: JaygahdarReducer } = accountSlice;
