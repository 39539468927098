import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  logo: ({ size }) => ({
    height: size === 'large' ? 70 : 50,
  }),
  logoButton: ({ size }) => ({
    padding: size === 'large' ? 5 : 0,
  }),
}));

export default function LogoButton({ size, href = '/', type = 'FTMS' }) {
  const classes = useStyles({ size });
  return (
    <Tooltip title={type} arrow >
      <IconButton className={classes.logoButton}>
        <a href={href}>
          <img
            src={process.env.PUBLIC_URL + '/logo.png'}
            alt="logo"
            className={classes.logo}
          />
        </a>
      </IconButton>
    </Tooltip >
  );
}
