import { createSlice } from '@reduxjs/toolkit';

import { Apis } from '../apis';
import { createAsyncThunkApi } from '../apis/cerateApiAsyncThunk';
import {
  createAccountUrl,
  createHaamelRoleUrl,
  createJaygahdarRoleUrl,
  createMasoolPetroshimiRoleUrl,
  getAllHaamelsUrl,
} from '../constants/urls';

export const createAccountAction = createAsyncThunkApi(
  'role/createAccountAction',
  Apis.POST,
  createAccountUrl,
  {
    defaultNotification: {
      success: 'حساب کاربری با موفقیت ایجاد شد.',
      error: 'مشکلی در ایجاد حساب کاربری جدید وجود داشت.',
    },
  }
);

export const createJaygahdarAction = createAsyncThunkApi(
  'role/createJaygahdarAction',
  Apis.POST,
  createJaygahdarRoleUrl,
  {
    defaultNotification: {
      success: 'کاربر جایگاه‌دار با موفقیت ایجاد شد.',
      error: 'مشکلی در ایجاد کاربر جایگاه‌دار جدید وجود داشت.',
    },
  }
);

export const createHaamelAction = createAsyncThunkApi(
  'role/createHaamelAction',
  Apis.POST,
  createHaamelRoleUrl,
  {
    defaultNotification: {
      success: 'کاربر حامل با موفقیت ایجاد شد.',
      error: 'مشکلی در ایجاد کاربر حامل جدید وجود داشت.',
    },
  }
);


export const createMasoolPetroshimiAction = createAsyncThunkApi(
  'role/createMasoolPetroshimiAction',
  Apis.POST,
  createMasoolPetroshimiRoleUrl,
  {
    defaultNotification: {
      success: 'کاربر مسئول پتروشیمی با موفقیت ایجاد شد.',
      error: 'مشکلی در ایجاد کاربر مسئول پتروشیمی جدید وجود داشت.',
    },
  }
);

export const getAllHaamelsAction = createAsyncThunkApi(
  'role/getAllHaamelsAction',
  Apis.GET,
  getAllHaamelsUrl,
  {
    defaultNotification: {
      error: 'مشکلی در ایجاد دریافت  جدید وجود داشت.',
    },
  }
);

const initialState = {
  newUser: {},
  allHaamels: [],
};

const isFetching = (state) => {
  state.isFetching = true;
};

const isNotFetching = (state) => {
  state.isFetching = false;
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: {
    [createAccountAction.pending.toString()]: isFetching,
    [createAccountAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.newUser = response.user;
      state.isFetching = false;
    },
    [createAccountAction.rejected.toString()]: isNotFetching,


    [getAllHaamelsAction.pending.toString()]: isFetching,
    [getAllHaamelsAction.fulfilled.toString()]: (state, { payload: { response } }) => {
      state.allHaamels = response;
      state.isFetching = false;
    },
    [getAllHaamelsAction.rejected.toString()]: isNotFetching,

  },
});

export const { logout: logoutAction } = accountSlice.actions;

export const { reducer: roleReducer } = accountSlice;
